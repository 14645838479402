@media screen and (max-width: 600px) {
  .intro h1{
    font-size: 40px!important;
  }
  .intro p{
    font-size: 15px;
  }
  .btn-custom{
    margin-bottom: 10px;
  }
  .navbar-brand{
    padding-left: 0px;
    padding-right: 0px;
  }
  #header{
    width: 100%;
  }
  #features {
    padding: 20px;
    width: 100%;
  }
  #about{
    width: 100%;
  }

  #header,
  #pom,
  #services,
  #team,
  #contact{
    width: 100%;
  }
  #testimonials{
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
}
